<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter"/>
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
        <div class="btn-left-block-i"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_selectAll'])"></span>
          <DefaultCheckbox
              :label="$t('common_selectAll.localization_value.value')"
              @input="(val) => $emit('selectAll', val)"
          />
        </div>
        <div v-if="isAdmin" class="btn-left-block-i">
          <div class="btn-left-block__list">
            <div class="btn-left-block__list__i">
              <TopTableBoxesBtn
                  class="btn-left-block-i--link"
                  :type="'dollar'"
                  :title="'forbiddenGoods_ToBalance'"
                  @click.native="$emit('approve')"
              />
            </div>
            <div class="btn-left-block__list__i">
              <TopTableBoxesBtn
                  class="btn-left-block-i--link"
                  :type="'dollar'"
                  :title="'forbiddenGoods_Refund'"
                  @click.native="$emit('refund')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TopTableBoxesBtn from "../../../../../../coreComponents/Buttons/TopTableBoxesBtn/TopTableBoxesBtn";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";


  export default {
    name: "OtherShippersHead",
    components: {
      TopTableBoxesBtn,
      DefaultCheckbox,
      // MainButton,
      ToggleFilterButton,
      // SearchEngine,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {}
    },

    methods: {

    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";


</style>

