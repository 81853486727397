<template>
  <div class="fragment">
    <div class="site-table-wrap small-table">

      <div class="detail-page__right p-0">
        <CardRightBlock>

          <template slot="body">
            <div class="order-create__section">
              <div class="custom-row">
                <div class="custom-col">

                  <template>
                    <div class="section-label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['freshChat_messagesTranslations'])"></div>
                      {{ $t('freshChat_messagesTranslations.localization_value.value') }}
                    </div>
                    <div class="order-create__row custom-row">
                      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['freshChat_HolidaysEn'])"></div>
                        <TextareaDefault
                            :label="$t('freshChat_HolidaysEn.localization_value.value')"
                            v-bind:class="{'ui-no-valid': FCM.validation.en}"
                            :error="FCM.validation.en"
                            :errorTxt="$t(`${FCM.validationTranslate.en}.localization_value.value`)"
                            v-model="FCM.data.en"
                        />
                      </div>
                      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['freshChat_HolidaysUa'])"></div>
                        <TextareaDefault
                            :label="$t('freshChat_HolidaysUa.localization_value.value')"
                            v-bind:class="{'ui-no-valid': FCM.validation.ua}"
                            :error="FCM.validation.ua"
                            :errorTxt="$t(`${FCM.validationTranslate.ua}.localization_value.value`)"
                            v-model="FCM.data.ua"
                        />
                      </div>
                      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['freshChat_HolidaysRu'])"></div>
                        <TextareaDefault
                            :label="$t('freshChat_HolidaysRu.localization_value.value')"
                            v-bind:class="{'ui-no-valid': FCM.validation.ru}"
                            :error="FCM.validation.ru"
                            :errorTxt="$t(`${FCM.validationTranslate.ru}.localization_value.value`)"
                            v-model="FCM.data.ru"
                        />
                      </div>
                    </div>
                  </template>

                </div>
              </div>
            </div>
          </template>

          <template slot="footer">
            <div class="order-create__footer">

              <div class="order-create__footer-link">

              </div>

              <div class="order-create__footer-btn ml-auto"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_create'])"></div>

                <MainButton
                    class="order-create__footer-btn-i"
                    :value="$t('common_update.localization_value.value')"
                    @click.native="save"
                />
              </div>

            </div>
          </template>
        </CardRightBlock>

      </div>

    </div>


  </div>
</template>

<script>
  // import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  // import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  // import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  // import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  // import {mixinDetictingMobile} from '../../../../../../../../mixins/mobileFunctions'
  // import FreshChatPopup from "../../../../../popups/FreshChatPopup/FreshChatPopup";
  // import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";
  // import FreshChatTableMobile from "./FreshChatTableMobile/FreshChatTableMobile";

  import CardRightBlock from "@/components/coreComponents/CardRightBlock/CardRightBlock";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";
  import {FreshChatMessages} from "@/components/modules/FreshChatModule/models/FreshChatMessages";
  export default {
    name: "FreshChatMessages",
    components: {
      TextareaDefault,
      MainButton,
      CardRightBlock
      // FreshChatTableMobile,
      // NoResult,
      // StatusIcoBtn,
      // ManagerButton,
      // LinkButton,
      // ShowMore,
      // FreshChatPopup,
    },

    // mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        FCM: new FreshChatMessages(),

      }
    },

    mounted() {
      this.$store.dispatch('getFreshChatMessages').then((response) => {
        this.FCM.setItem(this.getRespData(response))
      })
    },

    methods: {
      save() {
        if(!this.FCM.firstValidation()) return

        let saveType = 'updateFreshChatMessages'

        let data = this.FCM.prepareSave(this.$store.getters.GET_LANGUAGES)

        this.$store.dispatch(saveType, data).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              this.openNotify('success', 'common_notificationRecordChanged')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }

    }
  }
</script>

<style scoped lang="scss">
  .comment-row {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 115px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  @media (max-width: 1400px) {
    .comment-row {
      width: 100px;
    }
  }

  .table-manage-list .manager-btn {
    width: 200px;
  }

  @media (max-width: 1400px) {
    .table-manage-list .manager-btn {
      width: 100px;
    }

    .user-field-admin {
      flex-wrap: wrap;
      display: flex;
    }

    .hide-1400 {
      display: none;
    }
  }

  /*.site-table thead th:first-child,*/
  /*.site-table tbody td:first-child {*/
  /*padding-right: 0;*/
  /*}*/

  /*.site-table thead th:nth-child(2),*/
  /*.site-table tbody td:nth-child(2) {*/
  /*padding-left: 0;*/
  /*}*/

  /*.site-table thead th:last-child,*/
  /*.site-table tbody td:last-child {*/
  /*padding-right: 0;*/
  /*}*/

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  .size-24 {
    width: 24px;
    height: 24px;
    margin-top: -2px;
  }


</style>
