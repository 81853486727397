<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['cuspayments_transactions'])"></div>
        {{$t('cuspayments_transactions.localization_value.value')}}: {{transaction.id}}
      </div>
    </template>
    <template slot="body">
      <div class="transaction-info__content">
        <div class="transaction-info__section">
          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_date'])"></div>
                {{$t('common_date.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ transaction.created_at | moment("DD MMM, YYYY") }}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_lotInvoiceNumber'])"></div>
                {{$t('cuspayments_lotInvoiceNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="transaction.shop && transaction.shop.shop_type.id === SHOP_TYPES.ukrainiantreasures.id && transaction.payment_transaction_detail.remote_url">
                  <a
                      target="_blank"
                      style="word-break: break-all;"
                      class="site-link site-link--light brown clear-after text-decoration-underline"
                      :href="transaction.payment_transaction_detail.remote_url">
                    <ValueHelper
                        :value="transaction"
                        :deep="'payment_transaction_detail.remote_url'"
                    />
                  </a>
                </template>
                <template v-else-if="transaction.shop && transaction.shop.shop_type">
                  <a target="_blank" :href="getShopLink(transaction.shop.shop_type.name) + transaction.payment_transaction_detail.l_number0"
                     class="site-link site-link--light brown clear-after text-decoration-underline">
                    <ValueHelper
                            v-if="transaction.payment_transaction_detail.l_number0 !== null"
                            :value="transaction.payment_transaction_detail.l_number0"
                    />
                    <ValueHelper
                            v-else
                            :value="transaction.payment_transaction_detail.invoice_number"
                    />
                  </a>
                </template>
                <template v-else>
                  <ValueHelper
                          v-if="transaction.payment_transaction_detail.l_number0 !== null"
                          :value="transaction.payment_transaction_detail.l_number0"
                  />
                  <ValueHelper
                          v-else
                          :value="transaction.payment_transaction_detail.invoice_number"
                  />
                </template>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_trackingNumber'])"></div>
                {{$t('cuspayments_trackingNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="transaction.tracking_number !== null">
                  {{transaction.tracking_number}}
                </template>
                <template v-else>
                  —
                </template>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_name'])"></div>
                {{$t('cuspayments_name.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="transaction.payment_transaction_detail.payment_transaction_contact.full_name"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_email'])"></div>
                {{$t('common_email.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="transaction.payment_transaction_detail.payment_transaction_contact.email"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
                {{$t('common_address.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="transaction.payment_transaction_detail.payment_transaction_contact.address"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
                {{$t('common_city.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="transaction.payment_transaction_detail.payment_transaction_contact.city"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_zipCode'])"></div>
                {{$t('cuspayments_zipCode.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="transaction.payment_transaction_detail.payment_transaction_contact.zip_code"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_state'])"></div>
                {{$t('common_state.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="transaction.payment_transaction_detail.payment_transaction_contact.state"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
                {{$t('common_country.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="transaction.payment_transaction_detail.payment_transaction_contact.country_code"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
                {{$t('common_phone.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="transaction.payment_transaction_detail.payment_transaction_contact.phone">
                  {{transaction.payment_transaction_detail.payment_transaction_contact.phone}}
                </template>
                <template v-else>
                  —
                </template>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_addressStatus'])"></div>
                {{$t('cuspayments_addressStatus.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="transaction.payment_transaction_detail.address_status"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['importO_transactionNumber'])"></div>
                {{$t('importO_transactionNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt d-flex">
                <div class="transaction-info__ico mr-2">
                  <img src="/img/company-icons-group/transaction-paypal-icon.png" alt="ico">
                </div>
                {{transaction.transaction_id}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_totalAmount'])"></div>
                {{$t('cuspayments_totalAmount.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                ${{transaction.payment_total}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_paypalFee'])"></div>
                {{$t('cuspayments_paypalFee.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                ${{transaction.payment_commission}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_total'])"></div>
                {{$t('cuspayments_total.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                ${{transaction.warehouse_amount}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_tax'])"></div>
                {{$t('cuspayments_tax.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                        :value="transaction.payment_tax"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_netAmount'])"></div>
                {{$t('cuspayments_netAmount.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                ${{transaction.user_amount}}
              </div>
            </div>
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--System Commission  (-&#45;&#45;)-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!--$0  (-&#45;&#45;)-->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--User Bonus and Transaction Amount  (-&#45;&#45;)-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!--$0  (-&#45;&#45;)-->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--Funds available  (-&#45;&#45;)-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!--$36.57  (-&#45;&#45;)-->
              <!--</div>-->
            <!--</div>-->
            <div class="transaction-info__col w-100">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_items'])"></div>
                {{$t('cuspayments_items.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="transaction.payment_transaction_detail.items"
                />
              </div>
            </div>
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--Orders FBM-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!-- - -->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--Status-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!--Completed-->
              <!--</div>-->
            <!--</div>-->
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['forbiddenGoods_ToBalance','forbiddenGoods_Refund'])"></div>
        <MainButton
            class="white-space-line mr-2"
            :value="$t('forbiddenGoods_ToBalance.localization_value.value')"
            @click.native="$emit('approve')"
        />

        <MainButton
            class="white-space-line"
            :value="$t('forbiddenGoods_Refund.localization_value.value')"
            @click.native="$emit('refund')"
        />

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import {mapGetters} from "vuex";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  import {DEFAULT_SHOP_TYPES, SHOP_TYPES} from "../../../../../staticData/staticVariables";


  export default {
    name: "InfoPopup",
    components: {
      ValueHelper,
      Modal,
      MainButton,
    },

    props: {
      itemId: [Number, String]
    },

    computed: {
      ...mapGetters({
        transaction: 'getForbiddenGoodsItem',
      })
    },

    data() {
      return {
        SHOP_TYPES: SHOP_TYPES,
      }
    },

    mounted() {
      // if(this.itemId){
      // }
    },

    methods: {
      getShopLink(shopType) {
        // this.shopType = this.$store.getters.getCusPaymentsItem.shop ? this.$store.getters.getCusPaymentsItem.shop.shop_type.name : ''
        if(shopType === DEFAULT_SHOP_TYPES.etsy.type){
          return 'https://www.etsy.com/listing/'
        }
        if(shopType === DEFAULT_SHOP_TYPES.ebay.type){
          return 'https://www.ebay.com/itm/'
        }
      }
    }

  }

</script>

<style lang="scss" scoped>
  @media(max-width: 550px){
    .transaction-info__col{
      width: 100%;
    }

    .scoped-btns > button{
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
      margin-bottom: 10px;
    }
  }
</style>
