<template>
  <modal
          @close="$emit('close')"
          class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'shippingInsurances_AddForbiddenKeyword',
            'shippingInsurances_ChangeForbiddenKeyword',
          ])"></div>
        <template v-if="type === 'create'">
          {{$t('shippingInsurances_AddForbiddenKeyword.localization_value.value')}}
        </template>
        <template v-else>
          {{$t('shippingInsurances_ChangeForbiddenKeyword.localization_value.value')}}
        </template>
      </div>

    </template>
    <template slot="body">
      <div class="profiles-expenses__content">

        <div class="profiles-expenses__row custom-row">
          <div class="profiles-expenses__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['shippingInsurances_ForbiddenGoodsKeyword'])"></div>
            <DefaultInput
                    :label="$t('shippingInsurances_ForbiddenGoodsKeyword.localization_value.value')"
                    v-bind:class="{'ui-no-valid': SI.validation.name}"
                    :error="SI.validation.name"
                    :errorTxt="$t(`${SI.validationTranslate.name}.localization_value.value`)"
                    :type="'text'"
                    v-model="SI.data.name"
            />
          </div>
          <div class="profiles-expenses__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['shippingInsurances_ForbiddenGoodsKeyword'])"></div>
            <DefaultInput
                    :label="$t('shippingInsurances_ForbiddenGoodsKeyword.localization_value.value')"
                    v-bind:class="{'ui-no-valid': SI.validation.country}"
                    :error="SI.validation.country"
                    :errorTxt="$t(`${SI.validationTranslate.country}.localization_value.value`)"
                    :type="'text'"
                    v-model="SI.data.country"
            />
          </div>
          <div class="profiles-expenses__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['shippingInsurances_ForbiddenGoodsKeyword'])"></div>
            <DefaultInput
                    :label="$t('shippingInsurances_ForbiddenGoodsKeyword.localization_value.value')"
                    v-bind:class="{'ui-no-valid': SI.validation.price}"
                    :error="SI.validation.price"
                    :errorTxt="$t(`${SI.validationTranslate.price}.localization_value.value`)"
                    :type="'text'"
                    v-model="SI.data.price"
            />
          </div>
          <div class="profiles-expenses__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['shippingInsurances_ForbiddenGoodsKeyword'])"></div>
            <DefaultInput
                    :label="$t('shippingInsurances_ForbiddenGoodsKeyword.localization_value.value')"
                    v-bind:class="{'ui-no-valid': SI.validation.shippingCompany}"
                    :error="SI.validation.shippingCompany"
                    :errorTxt="$t(`${SI.validationTranslate.shippingCompany}.localization_value.value`)"
                    :type="'text'"
                    v-model="SI.data.shippingCompany"
            />
          </div>
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-between align-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="type === 'create'"
                :value="$t('common_create.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
        />
        <MainButton
                v-else
                :value="$t('common_update.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import {ShippingInsurances} from "../../models/ShippingInsurances";


  export default {
    name: "ShippingInsurancesPopup",
    components: {
      DefaultInput,
      Modal,
      MainButton,
    },


    props: {
      itemId: Number,
      type: String,
    },

    data() {
      return {
        SI: new ShippingInsurances()
      }
    },

    mounted() {
      if (this.itemId) {
        this.$store.dispatch('getShippingInsurances', this.itemId).then(() => {
          this.SI.setItem(this.$store.getters.getShippingInsurancesItem)
        }).catch(error => this.createErrorLog(error))
      }
    },

    methods: {
      save() {
        if (!this.SI.firstValidation()) return

        let saveType = 'createShippingInsurances'

        let data = this.SI.prepareSave()

        if (this.type === 'update') {
          saveType = 'updateShippingInsurances'
          data = {
            data: data,
            id: this.itemId
          }
        }

        this.$store.dispatch(saveType, data).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if (this.type === 'update') {
                this.openNotify('success', 'common_notificationRecordChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
              }
              this.$emit('close')
              this.$emit('reload')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }
    }

  }

</script>

<style lang="scss" scoped>
  @media(max-width: 550px) {
    .transaction-info__col {
      width: 100%;
    }

    .scoped-btns > button {
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
      margin-bottom: 10px;
    }
  }
</style>
