<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <div class="table-card__item-number table-card__item-number--small">
          {{item.name}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('freshChat_WorkingDayTimeFrom.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.from_time | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('freshChat_WorkingDayTimeTo.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.to_time | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('freshChat_Status.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div v-if="item.is_working_day"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['forbiddenKeywords_Active'])"></div>
                <StatusIcoBtn class="mr-1" :type="'delivered'"/>
                <!--                {{ $t('forbiddenKeywords_Active.localization_value.value') }}-->
              </div>
              <div v-else
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['forbiddenKeywords_Inactive'])"></div>
                <StatusIcoBtn class="mr-1" :type="'cancel'"/>
                <!--                {{ $t('forbiddenKeywords_Inactive.localization_value.value') }}-->
              </div>
            </div>
          </div>
        </div>
        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    @click.native="$emit('changeFreshChatPopup')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../../UI/buttons/LinkButton/LinkButton";
  import StatusIcoBtn from "../../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  export default {
    name: "FreshChatTableMobile",
    components: {StatusIcoBtn, LinkButton},

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
