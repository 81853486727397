import { render, staticRenderFns } from "./FreshChatHead.vue?vue&type=template&id=7d18053f&scoped=true"
import script from "./FreshChatHead.vue?vue&type=script&lang=js"
export * from "./FreshChatHead.vue?vue&type=script&lang=js"
import style0 from "./FreshChatHead.vue?vue&type=style&index=0&id=7d18053f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d18053f",
  null
  
)

export default component.exports