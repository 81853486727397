<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <!--<DefaultCheckbox class="mt-1"/>-->
        <div class="table-card__item-number table-card__item-number--small"
             @click="$emit('changeInfoPopup')"
        >
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('common_date.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('forbiddenGoods_Transaction.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'payment_transaction.transaction_id'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('forbiddenGoods_Store.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'payment_transaction.shop.shop_name'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('forbiddenGoods_ForbiddenGoodsKeyword.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.keyword}}
            </div>
          </div>
        </div>
        <div class="table-card__item-btn" v-if="isAdmin">
          <div class="table-card__item-btn-i table-btn">
            <MainButton
                    :value="$t('forbiddenGoods_ToBalance.localization_value.value')"
                    @click.native="$emit('approve')"
            />
          </div>
          <div class="table-card__item-btn-i table-btn">
            <MainButton
                    :value="$t('forbiddenGoods_Refund.localization_value.value')"
                    @click.native="$emit('refund')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  export default {
    name: "ForbiddenGoodsTableMobile",
    components: {ValueHelper, MainButton},

    props: {
      item: Object,
    },

    data(){
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
