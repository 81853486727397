<template>
  <OtherShippersTableAdmin/>
</template>

<script>
  import OtherShippersTableAdmin from "./OtherShippersTableAdmin/OtherShippersTableAdmin";

  export default {
    name: "OtherShippersTable",
    components: {
      OtherShippersTableAdmin,
    }
  }
</script>

<style scoped>

</style>