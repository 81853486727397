<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'freshChat_CreateHolidays',
            'freshChat_ChangeHolidays',
          ])"></div>

        <template v-if="type === 'create'">
          {{$t('freshChat_CreateHolidays.localization_value.value')}}
        </template>
        <template v-else>
          {{$t('freshChat_ChangeHolidays.localization_value.value')}}
        </template>
      </div>

    </template>
    <template slot="body">
      <div class="profiles-expenses__content">

        <div class="profiles-expenses__row custom-row">
          <div class="profiles-expenses__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['freshChat_HolidaysDate'])"></div>
            <DatePickerDefault
                :label="$t('freshChat_HolidaysDate.localization_value.value')"
                :value="FCH.data.date"
                class="date-picker-time-custom"
            >
              <template slot="body">
                <date-picker
                    v-model="FCH.data.date"
                    valueType="format"
                    :format="'YYYY-MM-DD'"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>

          <div class="profiles-expenses__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['freshChat_HolidaysEn'])"></div>
            <DefaultInput
                    :label="$t('freshChat_HolidaysEn.localization_value.value')"
                    v-bind:class="{'ui-no-valid': FCH.validation.en}"
                    :error="FCH.validation.en"
                    :errorTxt="$t(`${FCH.validationTranslate.en}.localization_value.value`)"
                    :type="'text'"
                    v-model="FCH.data.en"
            />
          </div>
          <div class="profiles-expenses__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['freshChat_HolidaysUa'])"></div>
            <DefaultInput
                    :label="$t('freshChat_HolidaysUa.localization_value.value')"
                    v-bind:class="{'ui-no-valid': FCH.validation.ua}"
                    :error="FCH.validation.ua"
                    :errorTxt="$t(`${FCH.validationTranslate.ua}.localization_value.value`)"
                    :type="'text'"
                    v-model="FCH.data.ua"
            />
          </div>
          <div class="profiles-expenses__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['freshChat_HolidaysRu'])"></div>
            <DefaultInput
                    :label="$t('freshChat_HolidaysRu.localization_value.value')"
                    v-bind:class="{'ui-no-valid': FCH.validation.ru}"
                    :error="FCH.validation.ru"
                    :errorTxt="$t(`${FCH.validationTranslate.ru}.localization_value.value`)"
                    :type="'text'"
                    v-model="FCH.data.ru"
            />
          </div>

        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-between align-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="type === 'create'"
                :value="$t('common_create.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
                v-bind:class="{'disabled-btn' : $store.getters.getHolidaysBtn}"
        />
        <MainButton
                v-else
                :value="$t('common_update.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
                v-bind:class="{'disabled-btn' : $store.getters.getHolidaysBtn}"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePicker from "vue2-datepicker";
  import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
  import {FreshChatHolidays} from "@/components/modules/FreshChatModule/models/FreshChatHolidays";


  export default {
    name: "FreshChatHolidaysPopup",
    components: {
      DefaultInput,
      Modal,
      MainButton,
      DatePicker,
      DatePickerDefault,
    },


    props: {
      itemId: Number,
      type: String,
    },

    data() {
      return {
        FCH: new FreshChatHolidays()
      }
    },

    mounted() {
      if(this.itemId){
        this.$store.dispatch('getHolidays', this.itemId).then((response) => {
          this.FCH.setItem(this.getRespData(response))
        }).catch(error => this.createErrorLog(error))
      }

    },

    methods: {
      save() {
        if(!this.FCH.firstValidation()) return

        let saveType = 'createHolidays'

        let data = this.FCH.prepareSave(this.$store.getters.GET_LANGUAGES)

        if (this.type === 'update') {
          saveType = 'updateHolidays'
          data = {
            data: data,
            id: this.itemId
          }
        }

        this.$store.dispatch(saveType, data).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if (this.type === 'update') {
                this.openNotify('success', 'common_notificationRecordChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
              }
              this.$emit('close')
              this.$emit('reload')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }
    }

  }

</script>

<style lang="scss" scoped>
  @media(max-width: 550px){
    .transaction-info__col{
      width: 100%;
    }

    .scoped-btns > button{
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
      margin-bottom: 10px;
    }
  }
</style>
