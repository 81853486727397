<template>
  <div class="fragment">
    <ForbiddenKeywordsTableAdmin
        v-if="isAdmin"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @reload="reload"
    />


  </div>

</template>

<script>
  import ForbiddenKeywordsTableAdmin from "./ForbiddenKeywordsAdmin/ForbiddenKeywordsAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ForbiddenKeywordsTable",
    components: {
      ForbiddenKeywordsTableAdmin,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getForbiddenKeywordsForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterKeyword: '',
        filterStatus: '',

        filterShipped: [],

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          keyword: this.$route.query.keyword,
          status: this.$route.query.status,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextForbiddenKeywordsPage', true)
        this.$store.dispatch('fetchForbiddenKeywords', url).then(() => {
          this.$store.commit('setNextForbiddenKeywordsPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter()
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'ForbiddenItemKeywordId',
            filterKeyword: 'ForbiddenItemKeyword',
          },
        )

        if(this.filterStatus === 'true') myQuery.where('ForbiddenItemKeywordIdiIsEnabled', '1')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }

  }
</script>

<style scoped>

</style>
