import { render, staticRenderFns } from "./ShippingInsurancesFilter.vue?vue&type=template&id=949d4d52&scoped=true"
import script from "./ShippingInsurancesFilter.vue?vue&type=script&lang=js"
export * from "./ShippingInsurancesFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "949d4d52",
  null
  
)

export default component.exports