<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <div class="table-card__item-number table-card__item-number--small">
          #{{item.id}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('freshChat_HolidaysDate.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.date | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('freshChat_HolidaysEn.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.translationStorage.en.text}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('freshChat_HolidaysUa.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.translationStorage.ua.text}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('freshChat_HolidaysRu.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.translationStorage.ru.text}}
            </div>
          </div>

        </div>
        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    @click.native="$emit('changeFreshChatPopup')"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('deleteItem')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../../UI/buttons/LinkButton/LinkButton";

  export default {
    name: "FreshChatTableMobile",
    components: {LinkButton},

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
