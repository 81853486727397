import { render, staticRenderFns } from "./OtherShippersTableAdmin.vue?vue&type=template&id=45d0cf32&scoped=true"
import script from "./OtherShippersTableAdmin.vue?vue&type=script&lang=js"
export * from "./OtherShippersTableAdmin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d0cf32",
  null
  
)

export default component.exports