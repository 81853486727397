<template>
  <div class="fragment">
    <FreshChatTableAdmin
        v-if="isAdmin"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        :navTabs="navTabs"
        @changeTab="changeTab"
        @resetFilter="resetFilter"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @reload="reload"
    />


  </div>

</template>

<script>
  import FreshChatTableAdmin from "./FreshChatAdmin/FreshChatAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "FreshChatTable",
    components: {
      FreshChatTableAdmin,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getFreshChatWorkingDaysForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterKeyword: '',
        filterStatus: '',
        filterType: '',

        filterShipped: [],

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          workingDays: {
            active: true,
            label: this.$t('freshChat_workingDays.localization_value.value'),
            name: 'workingDays',
          },
          holidays: {
            active: false,
            label: this.$t('freshChat_holidays.localization_value.value'),
            name: 'holidays',
          },
          messages: {
            active: false,
            label: this.$t('freshChat_messages.localization_value.value'),
            name: 'messages',
          },
        },
      }
    },

    mounted() {
      if(this.$route.query.type !== undefined){
        this.navTabs.workingDays.active = false
        this.navTabs[this.$route.query.type].active = true
      }
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          id: this.$route.query.id,
          keyword: this.$route.query.keyword,
          status: this.$route.query.status,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (this.navTabs.workingDays.active){
          this.$store.dispatch('fetchFreshChatWorkingDays', url).then(() => {
            this.$store.commit('setGlobalUpdateTable', false)
          })
        }

        if (this.navTabs.holidays.active){
          if (next) this.$store.commit('setNextHolidaysPage', true)
          this.$store.dispatch('fetchHolidays', url).then(() => {
            this.$store.commit('setNextHolidaysPage', false)
            this.$store.commit('setGlobalUpdateTable', false)
          })
        }


        this.checkCountFilter()
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        // this.generateFilterQueryParams(
        //   myQuery,
        //   {
        //     filterId: 'ForbiddenItemKeywordId',
        //     filterKeyword: 'ForbiddenItemKeyword',
        //   },
        // )
        //
        // if(this.filterStatus === 'true') myQuery.where('ForbiddenItemKeywordIdiIsEnabled', '1')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }

  }
</script>

<style scoped>

</style>
