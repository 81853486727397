<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <div class="table-card__item-number table-card__item-number--small">
          <ValueHelper :value="item" :deep="`user_personal_contact.user_full_name`" />
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('negativeBalance_User.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn :item="{ user: item }" />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('negativeBalance_balance.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.balance}}
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  export default {
    name: "NegativeBalanceTableMobile",
    components: {TableUserColumn, ValueHelper},

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
