<template>
  <div class="site-table-wrap small-table">
    <table class="site-table" v-if="!isMobileFunc()">
      <thead>
      <tr>
        <th></th>
        <th>ID</th>
        <th>Created</th>
        <th>User</th>
        <th>Paypal</th>
        <th>Customer</th>
        <th class="text-right">Ship, $</th>
        <th>Shipping Type</th>
        <th>Tracking Number</th>
        <th>Shipped</th>
        <th>Item Name</th>
        <th>Manage</th>
      </tr>
      </thead>
      <tbody>

      <tr>
        <td>
          <DefaultCheckbox class="empty-label"/>
        </td>
        <td>
            <span class="table-link btn-style"
                  @click="changeInfoPopup(true)"
            >
              4567
            </span>
        </td>
        <td>
          23 Aug, 2020
        </td>
        <td>
          <TableUserColumn
                  v-if="isAdmin"
                  :item="{}"
          />
        </td>
        <td>
          32353463dsdt46
        </td>
        <td>
          Nichole Wrinn
        </td>
        <td align="right">
          123
        </td>
        <td>
          Small bag
        </td>
        <td>
          435dfj3287rh38723
        </td>
        <td>
          30, Aug 2020
        </td>
        <td>
          <div class="table-row">
            <v-popover
                class="site-tooltip"
                offset="5"
                placement="top"
                trigger="hover"
            >
              <div class="comment-row">
                Christmas decor tree...
              </div>
              <template slot="popover">
                <p>
                  Christmas decor tree ipsum dolor sit amet, consectetur adipiscing elit. Donec und other words here to share with the person all the thoughts of the order or case
                </p>
              </template>
            </v-popover>
          </div>
        </td>
        <td>
          <div class="table-manage-list table-manage-list--small">
            <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
              <template slot="item">
                <LinkButton
                    :value="'Edit'"
                    :type="'edit'"
                />
              </template>
              <template slot="item">
                <LinkButton
                    :value="'Delete'"
                    :type="'delete'"
                />
              </template>
              <template slot="item">
                <LinkButton
                    :value="'Create Label'"
                    :type="'label'"
                />
              </template>
              <template slot="item">
                <LinkButton
                    :value="'Download Label'"
                    :type="'transfer'"
                />
              </template>
              <template slot="item">
                <LinkButton
                    :value="'Copy'"
                    :type="'copy'"
                />
              </template>
            </ManagerButton>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <DefaultCheckbox class="empty-label"/>
        </td>
        <td>
            <span class="table-link btn-style"
                  @click="changeInfoPopup(true)"
            >
              4567
            </span>
        </td>
        <td>
          23 Aug, 2020
        </td>
        <td>
          <TableUserColumn
                  v-if="isAdmin"
                  :item="{}"
          />
        </td>
        <td>
          32353463dsdt46
        </td>
        <td>
          Nichole Wrinn
        </td>
        <td align="right">
          123
        </td>
        <td>
          Small bag
        </td>
        <td>
          435dfj3287rh38723
        </td>
        <td>
          30, Aug 2020
        </td>
        <td>
          <div class="table-row">
            <v-popover
                class="site-tooltip"
                offset="5"
                placement="top"
                trigger="hover"
            >
              <div class="comment-row">
                Christmas decor tree...
              </div>
              <template slot="popover">
                <p>
                  Christmas decor tree ipsum dolor sit amet, consectetur adipiscing elit. Donec und other words here to share with the person all the thoughts of the order or case
                </p>
              </template>
            </v-popover>
          </div>
        </td>
        <td>
          <div class="table-manage-list table-manage-list--small">
            <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
              <template slot="item">
                <LinkButton
                    :value="'Edit'"
                    :type="'edit'"
                />
              </template>
              <template slot="item">
                <LinkButton
                    :value="'Delete'"
                    :type="'delete'"
                />
              </template>
              <template slot="item">
                <LinkButton
                    :value="'Create Label'"
                    :type="'label'"
                />
              </template>
              <template slot="item">
                <LinkButton
                    :value="'Download Label'"
                    :type="'transfer'"
                />
              </template>
              <template slot="item">
                <LinkButton
                    :value="'Copy'"
                    :type="'copy'"
                />
              </template>
            </ManagerButton>
          </div>
        </td>
      </tr>

      </tbody>
    </table>

    <div class="table-card" v-if="isMobileFunc()">
      <div class="table-card__list">
        <div class="table-card__item">
          <div class="table-card__item-content"
               :class="{show: show1}"
          >
            <div class="table-card__item-head">
              <div class="table-card__item-head-row d-flex">
                <DefaultCheckbox class="mt-1"/>
                <div class="table-card__item-number table-card__item-number--small"
                     @click="changeInfoPopup(true)"
                >
                  4567
                </div>
              </div>
              <div class="table-card__item-head-row">
                <div class="table-card__item-head-txt">
                  23 Aug, 2020
                </div>
              </div>
            </div>
            <transition name="slide">
              <div class="table-card__item-body"
                   v-if="show1"
              >
                <div class="table-card__item-row custom-row">
                  <div class="table-card__item-col custom-col custom-col--50">
                    <div class="table-card__item-label">
                      Created
                    </div>
                    <div class="table-card__item-info">
                      23 Aug, 2020
                    </div>
                  </div>
                  <div class="table-card__item-col custom-col custom-col--50">
                    <div class="table-card__item-label">
                      Shipped
                    </div>
                    <div class="table-card__item-info">
                      30, Aug 2020
                    </div>
                  </div>
                  <div class="table-card__item-col custom-col">
                    <div class="table-card__item-label">
                      User
                    </div>
                    <div class="table-card__item-info">
                      <div class="table-row d-flex flex-wrap">
                        <StatusIcoBtn
                            class="mr-1 t-0"
                            :type="'delivered'"
                        />
                        <LabelCircle
                            class="oval mr-1"
                            :color="'blue'"
                            :value="'NEW'"
                        />
                        <span class="table-link btn-style mr-1 white-space-line"
                              @click="changeStatisticPopup(true)"
                        >
                            Oksana Kisel
                          </span>

                        <span class="brick-btn mr-1">
                            FBM
                          </span>
                        <span class="brick-btn mr-1">
                            ATM
                          </span>
                        <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                            class="alone more-180 ml-3 mb-1"
                        >
                          <template slot="item">
                            <LinkButton
                                :value="'Mail with Client'"
                                :type="'letter'"
                                @click.native="changeSendEmailPopup(true)"
                            />
                          </template>
                          <template slot="item">
                            <LinkButton
                                :value="'View Comments'"
                                :type="'cloud-msg'"
                                @click.native="changeCommentsPopup(true)"
                            />
                          </template>
                          <template slot="item">
                            <LinkButton
                                :value="'Last Five IP'"
                                :type="'pin'"
                                @click.native="changeLastFiveIPPopup(true)"
                            />
                          </template>
                          <template slot="item">
                            <LinkButton
                                :value="'Client’s Statistics'"
                                :type="'statistic'"
                            />
                          </template>
                        </ManagerButton>
                      </div>
                    </div>
                  </div>
                  <div class="table-card__item-col custom-col custom-col--50">
                    <div class="table-card__item-label">
                      Paypal
                    </div>
                    <div class="table-card__item-info">
                      32353463dsdt46
                    </div>
                  </div>
                  <div class="table-card__item-col custom-col custom-col--50">
                    <div class="table-card__item-label">
                      Customer
                    </div>
                    <div class="table-card__item-info">
                      Nichole Wrinn
                    </div>
                  </div>
                  <div class="table-card__item-col custom-col custom-col--50">
                    <div class="table-card__item-label">
                      Ship, $
                    </div>
                    <div class="table-card__item-info">
                      123
                    </div>
                  </div>
                  <div class="table-card__item-col custom-col custom-col--50">
                    <div class="table-card__item-label">
                      Shipping Type
                    </div>
                    <div class="table-card__item-info">
                      Small bag
                    </div>
                  </div>
                  <div class="table-card__item-col custom-col">
                    <div class="table-card__item-label">
                      Tracking Number
                    </div>
                    <div class="table-card__item-info">
                      435dfj3287rh38723
                    </div>
                  </div>
                  <div class="table-card__item-col custom-col">
                    <div class="table-card__item-label">
                      Item Name
                    </div>
                    <div class="table-card__item-info">
                      Christmas decor tree...
                    </div>
                  </div>
                </div>
                <div class="table-card__item-btn">
                  <div class="table-card__item-btn-i">
                    <LinkButton
                        :value="'Edit'"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.ordersFBAEdit"
                    />
                  </div>
                  <div class="table-card__item-btn-i">
                    <LinkButton
                        :value="'Delete'"
                        :type="'delete'"
                        @click.native="removeProduct"
                    />
                  </div>
                  <div class="table-card__item-btn-i">
                    <LinkButton
                        :value="'Create Label'"
                        :type="'label'"
                    />
                  </div>
                  <div class="table-card__item-btn-i">
                    <LinkButton
                        :value="'Download Label'"
                        :type="'transfer'"
                    />
                  </div>
                  <div class="table-card__item-btn-i">
                    <LinkButton
                        :value="'Copy'"
                        :type="'copy'"
                    />
                  </div>
                </div>
              </div>
            </transition>
            <div class="table-card__item-footer">
              <div class="table-card__item-show"
                   @click="show1 = !show1"
              >
                Show More
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-bottom-btn">
      <div class="table-bottom-btn__inner">
        <ShowMore :count="'25'"/>
        <ExportBtn class="table-bottom-btn__right"/>
      </div>
    </div>

    <InfoPopup
        v-if="isModalInfoPopup"
        @close="changeInfoPopup(false)"
    />
  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LabelCircle from "../../../../../../../coreComponents/LabelCircle/LabelCircle";
  import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../../mixins/mobileFunctions'
  import InfoPopup from "../../../../../popups/InfoPopup/InfoPopup";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";

  export default {
    name: "Ukrposhta",
    components: {
      TableUserColumn,
      DefaultCheckbox,
      StatusIcoBtn,
      LabelCircle,
      ManagerButton,
      LinkButton,
      ShowMore,
      ExportBtn,
      InfoPopup,
    },

    mixins: [mixinDetictingMobile],

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalInfoPopup: false,
      }
    },

    methods:{
      changeInfoPopup(val){
        this.isModalInfoPopup = val
      }
    }
  }
</script>

<style scoped lang="scss">
  .comment-row{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 144px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  @media (max-width: 1400px){
    .comment-row{
      width: 100px;
    }
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }
</style>
