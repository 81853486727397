<template>
  <div>
    <FreshChatHead
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        :showFilter="showFilter"
        @changeTab="(data) => $emit('changeTab', data)"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">
<!--      <FreshChatFilter-->
<!--          :navTabs="navTabs"-->
<!--          :showFilter="showFilter"-->
<!--          :filterGetParams="filterGetParams"-->
<!--          @close="closeFilter"-->
<!--          @changeFilter="data => $emit('changeFilter', data)"-->
<!--          @resetFilter="$emit('resetFilter')"-->
<!--      />-->

      <FreshChatTable
          v-if="navTabs.workingDays.active"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
      />
      <FreshChatTableHolidays
          v-else-if="navTabs.holidays.active"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
      />
      <FreshChatMessages
          v-else-if="navTabs.messages.active"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


import FreshChatHead from "./FreshChatHead/FreshChatHead";
// import FreshChatFilter from "./FreshChatFilter/FreshChatFilter";
import FreshChatTable from "./FreshChatTable/FreshChatTableDays/FreshChatTable";
import FreshChatTableHolidays from "./FreshChatTable/FreshChatTableHolidays/FreshChatTable";
import FreshChatMessages
  from "@/components/modules/FreshChatModule/components/FreshChatTable/FreshChatAdmin/FreshChatTable/FreshChatMessages/FreshChatMessages";

export default {
  name: "FreshChatTableAdmin",

  components: {
    FreshChatMessages,
    FreshChatHead,
    // FreshChatFilter,
    FreshChatTable,
    FreshChatTableHolidays,
  },

  props: {
    countFilterParams: Number,
    filterGetParams: Object,
    navTabs: Object,
  },

  data() {
    return {
      showFilter: false,
    }
  },

  methods: {
    closeFilter() {
      this.showFilter = false
    },

    toggleFilter() {
      this.showFilter = !this.showFilter
    },
  }
}
</script>

<style scoped>

</style>
