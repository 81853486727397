<template>
  <div class="fragment">
    <template
            v-if="$store.getters.getUsers.length > 0 && $store.getters.getUsersLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'negativeBalance_User',
            'negativeBalance_balance',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('negativeBalance_User.localization_value.value') }}</th>
            <th>{{ $t('negativeBalance_balance.localization_value.value') }}</th>
            <th width="100%" class="hide-1400"></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getUsers" :key="index">
            <td>
              <TableUserColumn :item="{ user: item }" />
            </td>

            <td>
              <router-link class="table-link btn-style" :to="`${$store.getters.GET_PATHS.balanceHistory}?&userId=${item.id}&userName=${item.user_personal_contact.user_full_name}`">
                {{item.balance}}
              </router-link>
            </td>
            <td width="100%" class="hide-1400"></td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getUsers"
                 :key="index"
            >
              <NegativeBalanceTableMobile :item="item"/>
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getUsersCommonList.next_page_url !== null && $store.getters.getUsers.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextUsersPage}"
                    :count="$store.getters.getUsersCommonList.total - $store.getters.getUsersForPage * $store.getters.getUsersCommonList.current_page < $store.getters.getUsersForPage ?
                    $store.getters.getUsersCommonList.total - $store.getters.getUsersForPage * $store.getters.getUsersCommonList.current_page:
                    $store.getters.getUsersForPage"
            />
          </div>
        </div>

      </div>
    </template>

    <template
            v-if="$store.getters.getUsersLoading === false && $store.getters.getUsers.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>
  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import NegativeBalanceTableMobile from "./NegativeBalanceTableMobile/NegativeBalanceTableMobile";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";

  export default {
    name: "NegativeBalanceTable",
    components: {
      TableUserColumn,
      NegativeBalanceTableMobile,
      NoResult,
      ShowMore,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        itemId: -1,
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalNegativeBalancePopup: false,

      }
    },

    methods: {
      changeNegativeBalancePopup(val, id = false) {
        if (id) {
          this.itemId = id
        }
        this.isModalNegativeBalancePopup = val

        if (!val) {
          this.itemId = -1
        }
      },


      deleteItem(id) {
        let text = {
          title: 'common_deleteConfirm',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          this.$store.dispatch('deleteNegativeBalance', id).then(response => {
            if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.openNotify('success', 'common_notificationRecordDeleted')
              this.$emit('reload')
            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }
</script>

<style scoped lang="scss">
  .comment-row {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 115px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  @media (max-width: 1400px) {
    .comment-row {
      width: 100px;
    }
  }

  .table-manage-list .manager-btn {
    width: 200px;
  }

  @media (max-width: 1400px) {
    .table-manage-list .manager-btn {
      width: 100px;
    }

    .user-field-admin {
      flex-wrap: wrap;
      display: flex;
    }

    .hide-1400 {
      display: none;
    }
  }

  /*.site-table thead th:first-child,*/
  /*.site-table tbody td:first-child {*/
  /*padding-right: 0;*/
  /*}*/

  /*.site-table thead th:nth-child(2),*/
  /*.site-table tbody td:nth-child(2) {*/
  /*padding-left: 0;*/
  /*}*/

  /*.site-table thead th:last-child,*/
  /*.site-table tbody td:last-child {*/
  /*padding-right: 0;*/
  /*}*/

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  .size-24 {
    width: 24px;
    height: 24px;
    margin-top: -2px;
  }


</style>
