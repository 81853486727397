import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function FreshChatWorkingDays() {

  this.checkValid = checkValid

  this.data = {
    name: '',
    fromTime: '',
    toTime: '',
    status: false,
  }


  this.validation = {
    name: false,
    fromTime: false,
    toTime: false,
    status: false,
  }

  this.validationTranslate = {
    name: '',
    fromTime: '',
    toTime: '',
    status: '',
  }

  this.validationTxt = {
    name: false,
    fromTime: false,
    toTime: false,
    status: false,
  }

  /**
   * Getters
   */
  this.getName = () => {
    return this.data.name
  }
  this.getFromTime = () => {
    return this.data.fromTime
  }
  this.getToTime = () => {
    return this.data.toTime
  }
  this.getStatus = () => {
    return this.data.status
  }


  /**
   * Setters
   */
  this.setName = (val) => {
    this.data.name = val
  }
  this.setFromTime = (val) => {
    this.data.fromTime = val
  }
  this.setToTime = (val) => {
    this.data.toTime = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }

}

/**
 * Global Setters
 */

FreshChatWorkingDays.prototype.setItem = function(val) {

  this.setName(val.name)
  this.setFromTime(val.from_time)
  this.setToTime(val.to_time)
  this.setStatus(val.is_working_day)
}


/**
 * Prepare Data
 */

FreshChatWorkingDays.prototype.prepareSave = function() {
  return {
    name: this.getName(),
    from_time: this.getFromTime(),
    to_time: this.getToTime(),
    is_working_day: this.getStatus(),
  }
}


/**
 * Validations
 */

FreshChatWorkingDays.prototype.firstValidation = function() {
  let validationItems = {
    // keyword: this.getKeyword(),
  }

  let validationOptions = {
    // keyword: {type: ['empty']}
  }

  return this.checkValid(validationItems, validationOptions)
}
