import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function ShippingInsurances() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    name: '',
    country: '',
    price: '',
    shippingCompany: '',
  }


  this.validation = {
    id: false,
    name: false,
    country: false,
    price: false,
    shippingCompany: false,
  }

  this.validationTranslate = {
    id: '',
    name: '',
    country: '',
    price: '',
    shippingCompany: '',
  }

  this.validationTxt = {
    id: false,
    name: false,
    country: false,
    price: false,
    shippingCompany: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getName = () => {
    return this.data.name
  }
  this.getCountry = () => {
    return this.data.country
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getShippingCompany = () => {
    return this.data.shippingCompany
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setName = (val) => {
    this.data.name = val
  }
  this.setCountry = (val) => {
    this.data.country = val
  }
  this.setPrice = (val) => {
    this.data.price = val
  }
  this.setShippingCompany = (val) => {
    this.data.shippingCompany = val
  }

}

/**
 * Global Setters
 */

ShippingInsurances.prototype.setItem = function(val) {

  this.setId(val.id)
  this.setName(val.name)
  this.setCountry(val.country === '1')
  this.setPrice(val.price === '1')
  this.setShippingCompany(val.shippingCompany === '1')
}


/**
 * Prepare Data
 */

ShippingInsurances.prototype.prepareSave = function() {
  return {
    name: this.getName(),
    country: this.getCountry(),
    price: this.getPrice(),
    shippingCompany: this.getShippingCompany(),
  }
}


/**
 * Validations
 */

ShippingInsurances.prototype.firstValidation = function() {
  let validationItems = {
    name: this.getName(),
    country: this.getCountry()?.id,
    price: this.getPrice(),
    shippingCompany: this.getShippingCompany(),
  }

  let validationOptions = {
    name: {type: ['empty']},
    country: {type: ['empty']},
    price: {type: ['empty']},
    shippingCompany: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}
