<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'freshChat_workingDays',
          'freshChat_holidays',
          'freshChat_messages',
        ])"></div>
      <div class="content-tabs content-tabs--separator"
           v-if="Object.keys($store.getters.getUserProfile).length > 0"
      >
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              {{item.label}}
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
<!--        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />-->
      </div>
    </div>

    <div class="light-bg-block">
<!--      <div class="btn-right-block"-->
<!--           v-if="navTabs.workingDays.active"-->
<!--      >-->
<!--          <MainButton class="btn-fit-content ml-3"-->
<!--                    :value="'Add Keyword'"-->
<!--                    :ico="'ico'"-->
<!--                    @click.native="changeFreshChatWorkingDaysPopup(true)"-->
<!--        >-->
<!--          <template slot="ico">-->
<!--            <PlusIconSVG class="btn-icon-plus"/>-->
<!--          </template>-->
<!--        </MainButton>-->
<!--      </div>-->
      <div class="btn-right-block"
           v-if="navTabs.holidays.active"
      >
          <MainButton class="btn-fit-content ml-3"
                    :value="'Add New'"
                    :ico="'ico'"
                    @click.native="changeFreshChatHolidaysPopup(true)"
        >
          <template slot="ico">
            <PlusIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
<!--      <div class="btn-right-block">-->
<!--          <MainButton class="btn-fit-content ml-3"-->
<!--                    :value="'Add Keyword'"-->
<!--                    :ico="'ico'"-->
<!--                    @click.native="changeFreshChatWorkingDaysPopup(true)"-->
<!--        >-->
<!--          <template slot="ico">-->
<!--            <PlusIconSVG class="btn-icon-plus"/>-->
<!--          </template>-->
<!--        </MainButton>-->
<!--      </div>-->
    </div>

    <FreshChatPopup
            v-if="isModalFreshChatPopup"
            :type="'create'"
            @reload="$emit('reload')"
            @close="changeFreshChatPopup(false)"
    />

    <FreshChatWorkingDaysPopup
            v-if="isModalFreshChatWorkingDaysPopup"
            :type="'create'"
            @reload="$emit('reload')"
            @close="changeFreshChatWorkingDaysPopup(false)"
    />

    <FreshChatHolidaysPopup
            v-if="isModalFreshChatHolidaysPopup"
            :type="'create'"
            @reload="$emit('reload')"
            @close="changeFreshChatHolidaysPopup(false)"
    />


  </div>
</template>



<script>
  // import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import FreshChatPopup from "../../../../popups/FreshChatPopup/FreshChatPopup";
  import FreshChatWorkingDaysPopup
    from "@/components/modules/FreshChatModule/popups/FreshChatWorkingDaysPopup/FreshChatWorkingDaysPopup";
  import FreshChatHolidaysPopup
    from "@/components/modules/FreshChatModule/popups/FreshChatHolidaysPopup/FreshChatHolidaysPopup";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "FreshChatHead",
    components: {
      FreshChatHolidaysPopup,
      FreshChatWorkingDaysPopup,
      FreshChatPopup,
      // ToggleFilterButton,
      MainButton,
      PlusIconSVG,
      // DefaultCheckbox,
      // SearchEngine,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    data(){
      return{

        isModalFreshChatPopup: false,
        isModalFreshChatWorkingDaysPopup: false,
        isModalFreshChatHolidaysPopup: false,
      }
    },

    methods: {

      changeFreshChatPopup(val, id = false) {
        if(id){
          this.orderId = id
        }
        this.isModalFreshChatPopup = val

        if(!val){
          this.orderId = -1
        }
      },

      changeFreshChatWorkingDaysPopup(val) {
        this.isModalFreshChatWorkingDaysPopup = val
      },

      changeFreshChatHolidaysPopup(val) {
        this.isModalFreshChatHolidaysPopup = val
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";


</style>

