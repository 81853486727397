<template>
  <div class="table-filter-wrap">
    <template
      v-if="activeTab === 1"
    >
      <Ukrposhta/>
    </template>
    <template
      v-if="activeTab === 2"
    >
      <NovaPoshta/>
    </template>
    <template
      v-if="activeTab === 3"
    >
      <Belposhta/>
    </template>
    <template
      v-if="activeTab === 4"
    >
      <CDEK/>
    </template>

  </div>
</template>

<script>
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import Ukrposhta from "./Ukrposhta/Ukrposhta";
  import NovaPoshta from "./NovaPoshta/NovaPoshta";
  import Belposhta from "./Belposhta/Belposhta";
  import CDEK from "./CDEK/CDEK";


  export default {
    name: "OtherShippersTable",

    components: {
      NovaPoshta,
      Ukrposhta,
      Belposhta,
      CDEK,
    },

    mixins: [mixinDetictingMobile],

    props:[
      'activeTab',
    ],


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

      }
    },

    methods: {
      closePopup(){
        // this.isModalProductsToWarehouse = false
      },

      cantDeleteProduct(){
        let text = {
          title: 'Ooops',
          txt: 'Sorry, you cannot uninstall this product',
          no: 'Ok'
        }

        let confirm = () => {
          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      removeProduct(){
        let text = {
          title: 'Delete Product?',
          txt: 'Are you sure you want to Delete Product?',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          // this.$store.dispatch('deleteShopPermission', id).then((response) => {
          //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          //     let shops = this.$store.getters.getShops
          //     shops.map((item, index) => {
          //       if(item.id === id) {
          //         shops[index].shop_oauth_param.key_and_token_exist = 0
          //       }
          //     })
          //   } else {
          //     setTimeout(() => {
          //       this.openNotify('success', 'Произошла ошибка')
          //     }, 200)
          //   }
          // })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      }
    }

  }

</script>

<style scoped>


</style>