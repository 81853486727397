<template>
  <div class="fragment">
    <ForbiddenGoodsTableSection
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            :isModalInfoPopup="isModalInfoPopup"
            :itemId="itemId"
            @resetFilter="resetFilter"
            @showMore="showMore"
            @changeFilter="changeFilter"
            @reload="reload"
            @approve="approve"
            @refund="refund"
            @changeInfoPopup="changeInfoPopup"
            @downloadFiles="downloadFiles"
    />
  </div>

</template>

<script>
  import ForbiddenGoodsTableSection from "./ForbiddenGoodsTableSection/ForbiddenGoodsTableSection";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {FORBIDDEN_TRANSACTION_STATUSES} from "../../../../../staticData/staticVariables";
  import {forbiddenGoodsSocketMixin} from "../../../../../mixins/socketMixins/socketMixin";

  export default {
    name: "ForbiddenGoodsTable",
    components: {
      // ForbiddenGoodsTableAdmin,
      ForbiddenGoodsTableSection,
    },

    mixins: [forbiddenGoodsSocketMixin, routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getForbiddenGoodsForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterUserName: '',
        filterId: '',
        filterUserId: '',
        filterTransactionNumber: '',
        filterItemName: '',
        filterTrackingNumber: '',
        filterZip: '',
        filterContactName: '',
        filterEmail: '',
        filterTotalAmount: '',
        filterTransactionKeyword: '',
        filterShopName: '',

        countFilterParams: 0,
        filterGetParams: {},

        isModalInfoPopup: false,
        itemId: -1,
      }
    },

    mounted() {
      this.filter()
    },

    beforeDestroy() {
      if (this.$store.getters.getRequestReturnSocket) {
        this.destroySocketChannel('ForbiddenGoodsRefund', 'setForbiddenGoodsSocket')
      }
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          transactionNumber: this.$route.query.transactionNumber,
          itemName: this.$route.query.itemName,
          trackingNumber: this.$route.query.trackingNumber,
          zip: this.$route.query.zip,
          contactName: this.$route.query.contactName,
          email: this.$route.query.email,
          totalAmount: this.$route.query.totalAmount,
          transactionKeyword: this.$route.query.transactionKeyword,
          shopName: this.$route.query.shopName,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextForbiddenGoodsPage', true)
        this.$store.dispatch('fetchForbiddenGoods', url).then(() => {
          this.$store.commit('setNextForbiddenGoodsPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'ForbiddenPaymentTransactionInstanceId',
            filterUserId: 'userId',
            filterTransactionNumber: 'transactionId',
            filterItemName: 'transactionItems',
            filterTrackingNumber: 'transactionTrackingNumber',
            filterZip: 'transactionContactZipCode',
            filterContactName: 'transactionContactFullName',
            filterEmail: 'transactionContactEmail',
            filterTotalAmount: 'transactionWarehouseAmount',
            filterTransactionKeyword: 'ForbiddenPaymentTransactionKeyword',
            filterShopName: 'store',
          },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn('between_created_at', date)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.$store.dispatch('getExportForbiddenGoods', {filter: url, exportType: type}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: `application/${type}`})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `Forbidden goods.${type}`
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        }).catch(error => this.createErrorLog(error))
      },

      approve(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let selectedIds = this.getCheckedRows('row-name')

        if (!id && !selectedIds.length) return

        let idsArray = id ? [parseInt(id)] : selectedIds

        console.log(idsArray, id);

        let confirm = () => {

          this.$store.dispatch('approveForbiddenGoods', {ids: idsArray}).then((response) => {
            if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                console.log(this.getRespData(response));
                if(id && !this.getRespData(response).process_result[id]){
                  let items = this.$store.getters.getForbiddenGoods
                  let item = this._.find(items, {id: parseInt(id)})
                  item.status = FORBIDDEN_TRANSACTION_STATUSES.ERROR_STATUS
                  this.openNotify('error', 'common_notificationUndefinedError')
                  return
                }
                else {
                  let hasErrorsFlag = false
                  let items = this.$store.getters.getForbiddenGoods

                  idsArray.forEach(idsItem => {
                    if (!this.getRespData(response).process_result[idsItem]) {
                      let item = this._.find(items, {id: parseInt(idsItem)})
                      item.status = FORBIDDEN_TRANSACTION_STATUSES.ERROR_STATUS
                      hasErrorsFlag = true
                    }
                  })

                  if (hasErrorsFlag) {
                    this.openNotify('error', 'common_notificationUndefinedError')
                    return
                  }
                }
                this.openNotify('success', 'common_notificationRecordChanged')
                this.reload()
                this.changeInfoPopup(false)
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      },
      refund(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let selectedIds = this.getCheckedRows('row-name')

        if (!id && !selectedIds.length) return

        let idsArray = id ? [parseInt(id)] : selectedIds

        let confirm = () => {

          /**
           * Create Socket Channel
           * @type {*|{channel, active, event, user}|{active}}
           */
          let prepareSocket = this.prepareCreatePrivateSocketChannel('ForbiddenGoodsRefund')
          if (prepareSocket.active) {
            this.createForbiddenGoodsSocket(
                prepareSocket,
                {
                  status: true,
                  successFunc: () => {
                    let items = this.$store.getters.getForbiddenGoods

                    if (id) {
                      let index = this._.findIndex(items, {id: id})
                      items.splice(index, 1)
                    }
                    else {
                      idsArray.forEach(idsItem => {
                        let index = this._.findIndex(items, {id: idsItem})
                        items.splice(index, 1)
                      })
                    }

                    this.openNotify('success', 'common_notificationSuccessRefund')
                  },
                },
                {
                  status: false,
                  errorFunc: () => {
                    let items = this.$store.getters.getForbiddenGoods

                    if (id) {
                      let item = this._.find(items, {id: parseInt(id)})
                      item.status = FORBIDDEN_TRANSACTION_STATUSES.ERROR_STATUS
                    }
                    else {
                      idsArray.forEach(idsItem => {
                        let item = this._.find(items, {id: parseInt(idsItem)})
                        item.status = FORBIDDEN_TRANSACTION_STATUSES.ERROR_STATUS
                      })
                    }

                    this.openNotify('error', 'common_notificationUndefinedError')
                  },
                }
            )
          }

          this.$store.dispatch('makeRefundForbiddenGoods', {ids: idsArray}).then((response) => {
            if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                if (id) {
                  let items = this.$store.getters.getForbiddenGoods
                  let item = this._.find(items, {id: parseInt(id)})
                  item.status = FORBIDDEN_TRANSACTION_STATUSES.IN_PROCESS_STATUS
                }
                else {
                  let items = this.$store.getters.getForbiddenGoods

                  idsArray.forEach(idsItem => {
                    let item = this._.find(items, {id: parseInt(idsItem)})
                    item.status = FORBIDDEN_TRANSACTION_STATUSES.IN_PROCESS_STATUS
                  })
                }


                this.changeInfoPopup(false)
                this.openNotify('success', 'common_notificationRecordChanged')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)

                /**
                 * Destroy Socket Channel
                 */
                if (this.$store.getters.getRequestReturnSocket) {
                  this.destroySocketChannel('ForbiddenGoodsRefund', 'setForbiddenGoodsSocket')
                }

                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                /**
                 * Destroy Socket Channel
                 */
                if (this.$store.getters.getRequestReturnSocket) {
                  this.destroySocketChannel('ForbiddenGoodsRefund', 'setForbiddenGoodsSocket')
                }

                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      },

      changeInfoPopup(val, id) {
        if (val) {
          this.itemId = id
          this.$store.dispatch('getForbiddenGoods', this.itemId).then(() => {
            this.isModalInfoPopup = val
          })
        } else {
          this.itemId = -1
          this.isModalInfoPopup = val
        }
      },

    }

  }
</script>

<style scoped>

</style>
