<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'forbiddenKeywords_AddForbiddenKeyword',
            'forbiddenKeywords_ChangeForbiddenKeyword',
          ])"></div>
        <template v-if="type === 'create'">
          {{$t('forbiddenKeywords_AddForbiddenKeyword.localization_value.value')}}
        </template>
        <template v-else>
          {{$t('forbiddenKeywords_ChangeForbiddenKeyword.localization_value.value')}}
        </template>
      </div>

    </template>
    <template slot="body">
      <div class="profiles-expenses__content">

        <div class="profiles-expenses__row custom-row">
          <div class="profiles-expenses__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['forbiddenKeywords_ForbiddenGoodsKeyword'])"></div>
            <DefaultInput
                    :label="$t('forbiddenKeywords_ForbiddenGoodsKeyword.localization_value.value')"
                    v-bind:class="{'ui-no-valid': FK.validation.keyword}"
                    :error="FK.validation.keyword"
                    :errorTxt="$t(`${FK.validationTranslate.keyword}.localization_value.value`)"
                    :type="'text'"
                    v-model="FK.data.keyword"
            />
          </div>

          <div class="profiles-expenses__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['forbiddenKeywords_Status'])"></div>
            <DefaultCheckbox
                    :label="$t('forbiddenKeywords_Status.localization_value.value')"
                    v-model="FK.data.status"
            />
          </div>
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-between align-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="type === 'create'"
                :value="$t('common_create.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
        />
        <MainButton
                v-else
                :value="$t('common_update.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import {ForbiddenKeywords} from "../../models/ForbiddenKeywords";


  export default {
    name: "ForbiddenKeywordsPopup",
    components: {
      DefaultInput,
      DefaultCheckbox,
      Modal,
      MainButton,
    },


    props: {
      itemId: Number,
      type: String,
    },

    data() {
      return {
        FK: new ForbiddenKeywords()
      }
    },

    mounted() {
      if(this.itemId){
        this.$store.dispatch('getForbiddenKeywords', this.itemId).then(() => {
          this.FK.setItem(this.$store.getters.getForbiddenKeywordsItem)
        }).catch(error => this.createErrorLog(error))
      }
    },

    methods: {
      save() {
        if(!this.FK.firstValidation()) return

        let saveType = 'createForbiddenKeywords'

        let data = this.FK.prepareSave()

        if (this.type === 'update') {
          saveType = 'updateForbiddenKeywords'
          data = {
            data: data,
            id: this.itemId
          }
        }

        this.$store.dispatch(saveType, data).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if (this.type === 'update') {
                this.openNotify('success', 'common_notificationRecordChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
              }
              this.$emit('close')
              this.$emit('reload')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }
    }

  }

</script>

<style lang="scss" scoped>
  @media(max-width: 550px){
    .transaction-info__col{
      width: 100%;
    }

    .scoped-btns > button{
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
      margin-bottom: 10px;
    }
  }
</style>
