<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      1042
    </template>
    <template slot="body">
      <div class="transaction-info__content">
        <div class="transaction-info__name">
          NovaPoshta Order
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            Order Details
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Doc Number
              </div>
              <div class="transaction-info__txt">
                238729878357
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Cargo Type
              </div>
              <div class="transaction-info__txt">
                Parcel
              </div>
            </div>
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Shipment Date
              </div>
              <div class="transaction-info__txt">
                29 Jan, 2021
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Estimated delivery Date
              </div>
              <div class="transaction-info__txt">
                31 Jan, 2021
              </div>
            </div>
          </div>

        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            Dimensions
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Length, sm
              </div>
              <div class="transaction-info__txt">
                16
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Width, sm
              </div>
              <div class="transaction-info__tx">
                15
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Height, sm
              </div>
              <div class="transaction-info__txt">
                32
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Weight (kg),
              </div>
              <div class="transaction-info__txt">
                0.89
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Volume (cub,m)
              </div>
              <div class="transaction-info__txt">
                0.01
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Volume weight
              </div>
              <div class="transaction-info__txt">
                1.8
              </div>
            </div>

          </div>
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            Delivery Details
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Packages q-ty
              </div>
              <div class="transaction-info__txt">
                1
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Volume weight
              </div>
              <div class="transaction-info__tx">
                1,8
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Declared Value
              </div>
              <div class="transaction-info__txt">
                200
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Delivery Cost
              </div>
              <div class="transaction-info__txt">
                46
              </div>
            </div>
          </div>
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            Description
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Service type
              </div>
              <div class="transaction-info__txt">
                Warehouse
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Cargo description
              </div>
              <div class="transaction-info__tx">
                Запчастини
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Address
              </div>
              <div class="transaction-info__txt">
                Office #292 (up to 30 kg)<br>
                Poltavska St, 10 Kyiv,<br>
                Ukraine, 20345
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Created
              </div>
              <div class="transaction-info__txt">
                29 Jan, 2021
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'


  export default {
    name: "InfoPopup",
    components: {
      Modal,
    }
  }

</script>

<style lang="scss">

</style>
