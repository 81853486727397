<template>
  <div>
    <OtherShippersHead
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeFilter="changeFilter"
        :showFilter="showFilter"
        :activeTab="activeTab"
        @changeTab="changeTab"
    />

    <div class="table-filter-wrap">
      <OtherShippersFilter
          :showFilter="showFilter"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
      />

      <OtherShippersTable
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          :activeTab="activeTab"
      />
    </div>
  </div>
</template>

<script>


  import OtherShippersHead from "./OtherShippersHead/OtherShippersHead";
  import OtherShippersFilter from "./OtherShippersFilter/OtherShippersFilter";
  import OtherShippersTable from "./OtherShippersTable/OtherShippersTable";

  export default {
    name: "OtherShippersTableUser",

    components: {
      OtherShippersHead,
      OtherShippersFilter,
      OtherShippersTable,
    },

    data(){
      return{
        showFilter: false,
        filterId: '',
        filterName: '',
        forPage: 10,
        page: 1,
        activeTab: 1,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      changeTab(id) {
        this.activeTab = id
      },
    }
  }
</script>

<style scoped>

</style>
