import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function ConsolidationUsers() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    name: '',
  }


  this.validation = {
    id: false,
    name: false,
  }

  this.validationTranslate = {
    id: '',
    name: '',
  }

  this.validationTxt = {
    id: false,
    name: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getName = () => {
    return this.data.name
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setName = (val) => {
    this.data.name = val
  }

}

/**
 * Global Setters
 */

ConsolidationUsers.prototype.setItem = function(val) {
  this.setId(val.id)
  this.setName(val.name)
}


/**
 * Prepare Data
 */

ConsolidationUsers.prototype.prepareSave = function() {
  return {
    name: this.getName(),
  }
}


/**
 * Validations
 */

ConsolidationUsers.prototype.firstValidation = function() {
  let validationItems = {
    name: this.getName(),
  }

  let validationOptions = {
    name: {type: ['empty']}
  }

  return this.checkValid(validationItems, validationOptions)
}
