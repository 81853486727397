import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function FreshChatHolidays() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    date: null,
    en: '',
    ua: '',
    ru: '',
  }


  this.validation = {
    date: false,
    en: false,
    ua: false,
    ru: false,
  }

  this.validationTranslate = {
    date: '',
    en: '',
    ua: '',
    ru: '',
  }

  this.validationTxt = {
    date: false,
    en: false,
    ua: false,
    ru: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getDate = () => {
    return this.data.date
  }
  this.getEn = () => {
    return this.data.en
  }
  this.getUa = () => {
    return this.data.ua
  }
  this.getRu = () => {
    return this.data.ru
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setDate = (val) => {
    this.data.date = val
  }
  this.setEn = (val) => {
    this.data.en = val
  }
  this.setUa = (val) => {
    this.data.ua = val
  }
  this.setRu = (val) => {
    this.data.ru = val
  }

}

/**
 * Global Setters
 */

FreshChatHolidays.prototype.setItem = function(val) {

  this.setDate(val.date)
  this.setEn(val.translationStorage.en.text)
  this.setUa(val.translationStorage.ua.text)
  this.setRu(val.translationStorage.ru.text)
}


/**
 * Prepare Data
 */

FreshChatHolidays.prototype.prepareSave = function(lang) {
  let translations = {}
  lang.map(item => {
    if(item.locale === 'en'){
      translations[item.id] = {
        text: this.getEn()
      }
    }
    if(item.locale === 'ua'){
      translations[item.id] = {
        text: this.getUa()
      }
    }
    if(item.locale === 'ru'){
      translations[item.id] = {
        text: this.getRu()
      }
    }
  })
  return {
    date: this.getDate(),
    translations: translations,
  }
}


/**
 * Validations
 */

FreshChatHolidays.prototype.firstValidation = function() {
  let validationItems = {
    date: this.getDate(),
    en: this.getEn(),
    ua: this.getUa(),
    ru: this.getRu(),
  }

  let validationOptions = {
    date: {type: ['empty']},
    en: {type: ['empty']},
    ua: {type: ['empty']},
    ru: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}
