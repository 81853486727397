var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[(_vm.$store.getters.getForbiddenGoods.length > 0 && _vm.$store.getters.getForbiddenGoodsLoading !== true)?[_c('div',{staticClass:"site-table-wrap small-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'forbiddenGoods_ID',
          'common_date',
          'forbiddenGoods_Transaction',
          'forbiddenGoods_Store',
          'forbiddenGoods_ForbiddenGoodsKeyword',
          'common_manage',
          'forbiddenGoods_ToBalance',
          'forbiddenGoods_Refund',
        ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(_vm._s(_vm.$t('forbiddenGoods_ID.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('common_date.localization_value.value')))]),(_vm.isAdmin)?_c('th',[_vm._v(" "+_vm._s(_vm.$t('common_user.localization_value.value')))]):_vm._e(),_c('th',[_vm._v(_vm._s(_vm.$t('forbiddenGoods_Transaction.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('forbiddenGoods_Store.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('forbiddenGoods_ForbiddenGoodsKeyword.localization_value.value')))]),_c('th',{staticClass:"hide-1400",attrs:{"width":"100%"}}),(_vm.isAdmin)?_c('th',[_vm._v(_vm._s(_vm.$t('common_manage.localization_value.value')))]):_vm._e()])]),_c('tbody',_vm._l((_vm.$store.getters.getForbiddenGoods),function(item,index){return _c('tr',{key:index,class:{
          'site-table__wait-row': item.status === _vm.FORBIDDEN_TRANSACTION_STATUSES.ERROR_STATUS,
          'site-table__blue-row': item.status === _vm.FORBIDDEN_TRANSACTION_STATUSES.IN_PROCESS_STATUS
        }},[_c('td',[_c('DefaultCheckbox',{staticClass:"empty-label",attrs:{"selectedNow":_vm.selectedNow,"dataValue":item.id}})],1),_c('td',[_c('span',{staticClass:"table-link btn-style",on:{"click":function($event){return _vm.$emit('changeInfoPopup', true, item.payment_transaction.id)}}},[_vm._v(" #"+_vm._s(item.id)+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD MMM, YYYY"))+" ")]),(_vm.isAdmin)?_c('td',[(_vm.isAdmin)?_c('TableUserColumn',{attrs:{"item":item.payment_transaction}}):_vm._e()],1):_vm._e(),_c('td',[_c('ValueHelper',{attrs:{"value":item,"deep":'payment_transaction.transaction_id'}})],1),_c('td',[_c('ValueHelper',{attrs:{"value":item,"deep":'payment_transaction.shop.shop_name'}})],1),_c('td',[_vm._v(" "+_vm._s(item.keyword)+" ")]),_c('td',{staticClass:"hide-1400"}),_c('td',{staticClass:"p-0"},[(_vm.isAdmin &&
                 item.status !== _vm.FORBIDDEN_TRANSACTION_STATUSES.IN_PROCESS_STATUS)?_c('div',{staticClass:"table-btn d-flex"},[_c('MainButton',{staticClass:"mr-2",attrs:{"value":_vm.$t('forbiddenGoods_ToBalance.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('approve', item.id)}}}),_c('MainButton',{staticClass:"mr-2",attrs:{"value":_vm.$t('forbiddenGoods_Refund.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('refund', item.id)}}})],1):_vm._e()])])}),0)]):_vm._e(),(_vm.isMobileFunc())?_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list"},_vm._l((_vm.$store.getters.getForbiddenGoods),function(item,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('ForbiddenGoodsTableMobile',{attrs:{"item":item},on:{"changeInfoPopup":function($event){return _vm.$emit('changeInfoPopup', true, item.payment_transaction.id)},"approve":function($event){return _vm.$emit('approve', item.id)},"refund":function($event){return _vm.$emit('refund', item.id)}}})],1)}),0)]):_vm._e(),_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[(_vm.$store.getters.getForbiddenGoodsCommonList.next_page_url !== null && _vm.$store.getters.getForbiddenGoods.length > 0)?_c('ShowMore',{class:{'disabled-btn' : _vm.$store.getters.getNextForbiddenGoodsPage},attrs:{"count":_vm.$store.getters.getForbiddenGoodsCommonList.total - _vm.$store.getters.getForbiddenGoodsForPage * _vm.$store.getters.getForbiddenGoodsCommonList.current_page < _vm.$store.getters.getForbiddenGoodsForPage ?
                  _vm.$store.getters.getForbiddenGoodsCommonList.total - _vm.$store.getters.getForbiddenGoodsForPage * _vm.$store.getters.getForbiddenGoodsCommonList.current_page:
                  _vm.$store.getters.getForbiddenGoodsForPage},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e(),_c('ExportBtn',{staticClass:"table-bottom-btn__right",on:{"downloadFiles":type => _vm.$emit('downloadFiles', type)}})],1)])])]:_vm._e(),(_vm.$store.getters.getForbiddenGoodsLoading === false && _vm.$store.getters.getForbiddenGoods.length === 0)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')},on:{"resetFilter":function($event){return _vm.$emit('resetFilter')}}})]:_vm._e(),(_vm.isModalInfoPopup)?_c('InfoPopup',{attrs:{"itemId":_vm.itemId},on:{"reload":function($event){return _vm.$emit('reload')},"approve":function($event){return _vm.$emit('approve', _vm.itemId)},"refund":function($event){return _vm.$emit('refund', _vm.itemId)},"close":function($event){return _vm.$emit('changeInfoPopup', false)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }