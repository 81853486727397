<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
        <div class="content-tabs__item " v-bind:class="{active: tab === 1}" @click="changeTab(1)">
          <div class="content-tabs__ico">
            Ukrposhta
          </div>
        </div>
        <div class="content-tabs__item" v-bind:class="{active: tab === 2}" @click="changeTab(2)">
          <div class="content-tabs__ico">
            NovaPoshta
          </div>
        </div>
        <div class="content-tabs__item" v-bind:class="{active: tab === 3}" @click="changeTab(3)">
          <div class="content-tabs__ico">
            Belposhta
          </div>
        </div>
        <div class="content-tabs__item" v-bind:class="{active: tab === 4}" @click="changeTab(4)">
          <div class="content-tabs__ico">
            CDEK
          </div>
        </div>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <!--<div class="light-bg-block">-->
      <!--<div class="btn-right-block">-->
        <!--<router-link :to="$store.getters.GET_PATHS.ordersFBACreation">-->
          <!--<MainButton class="btn-fit-content ml-3"-->
                      <!--:value="'Add Order'"-->
                      <!--:ico="'ico'"-->
          <!--&gt;-->
            <!--<template slot="ico">-->
              <!--<PlusIconSVG class="btn-icon-plus"/>-->
            <!--</template>-->
          <!--</MainButton>-->
        <!--</router-link>-->
      <!--</div>-->
    <!--</div>-->



  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "OtherShippersHead",
    components: {
      ToggleFilterButton,
      // MainButton,
      // PlusIconSVG,
      // DefaultCheckbox,
      // SearchEngine,
    },

    props: [
      'showFilter',
      'activeTab'
    ],

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
        countFilterParams: 0,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      openHTMLCodePopup(){
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";


</style>

