<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'freshChat_ChangeWorkingDay',
          ])"></div>
        {{$t('freshChat_ChangeWorkingDay.localization_value.value')}}
      </div>

    </template>
    <template slot="body">
      <div class="profiles-expenses__content">

        <div class="profiles-expenses__row custom-row">
          <div class="profiles-expenses__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['freshChat_WorkingDayName'])"></div>
            <DefaultInput
                    :label="$t('freshChat_WorkingDayName.localization_value.value')"
                    v-bind:class="{'ui-no-valid': FCWD.validation.name}"
                    :error="FCWD.validation.name"
                    :errorTxt="$t(`${FCWD.validationTranslate.name}.localization_value.value`)"
                    :type="'text'"
                    v-model="FCWD.data.name"
            />
          </div>
          <div class="profiles-expenses__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['freshChat_WorkingDayTimeFrom'])"></div>
            <DatePickerDefault
                :label="$t('freshChat_WorkingDayTimeFrom.localization_value.value')"
                :value="FCWD.data.fromTime"
                class="date-picker-time-custom"
            >
<!--                v-bind:class="{'ui-no-valid': ex.validation.timeCourierFrom}"-->
<!--                :errorTxt="serverError ? ex.validationTxt.timeCourierFrom : $t(`${ex.validationTranslate.timeCourierFrom}.localization_value.value`)"-->
<!--                :error="ex.validation.timeCourierFrom"-->
              <template slot="body">
                <date-picker
                    v-model="FCWD.data.fromTime"
                    valueType="format"
                    format="HH:mm"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    type="time"
                ></date-picker>
<!--                    :time-picker-options="timePickerOptionsBeforeLunch"-->
              </template>
            </DatePickerDefault>
          </div>
          <div class="profiles-expenses__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['freshChat_WorkingDayTimeTo'])"></div>
            <DatePickerDefault
                :label="$t('freshChat_WorkingDayTimeTo.localization_value.value')"
                :value="FCWD.data.toTime"
                class="date-picker-time-custom"
            >
<!--                v-bind:class="{'ui-no-valid': ex.validation.timeCourierFrom}"-->
<!--                :errorTxt="serverError ? ex.validationTxt.timeCourierFrom : $t(`${ex.validationTranslate.timeCourierFrom}.localization_value.value`)"-->
<!--                :error="ex.validation.timeCourierFrom"-->
              <template slot="body">
                <date-picker
                    v-model="FCWD.data.toTime"
                    valueType="format"
                    format="HH:mm"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    type="time"
                ></date-picker>
<!--                    :time-picker-options="timePickerOptionsBeforeLunch"-->
              </template>
            </DatePickerDefault>
          </div>

          <div class="profiles-expenses__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['freshChat_Status'])"></div>
            <DefaultCheckbox
                    :label="$t('freshChat_Status.localization_value.value')"
                    v-model="FCWD.data.status"
            />
          </div>
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-between align-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="type === 'create'"
                :value="$t('common_create.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
        />
        <MainButton
                v-else
                :value="$t('common_update.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePicker from "vue2-datepicker";
  import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
  import {FreshChatWorkingDays} from "@/components/modules/FreshChatModule/models/FreshChatWorkingDays";


  export default {
    name: "FreshChatWorkingDaysPopup",
    components: {
      DefaultInput,
      DefaultCheckbox,
      Modal,
      MainButton,
      DatePicker,
      DatePickerDefault,
    },


    props: {
      itemName: String,
      type: String,
    },

    data() {
      return {
        FCWD: new FreshChatWorkingDays()
      }
    },

    mounted() {
      if(this.itemName){
        this.$store.dispatch('getFreshChatWorkingDays', this.itemName).then(() => {
          this.FCWD.setItem(this.$store.getters.getFreshChatWorkingDaysItem)
        }).catch(error => this.createErrorLog(error))
      }
    },

    methods: {
      save() {
        if(!this.FCWD.firstValidation()) return

        let saveType = 'updateFreshChatWorkingDays'

        let data = this.FCWD.prepareSave()

        // if (this.type === 'update') {
        //   saveType = 'updateFreshChatWorkingDays'
        //   data = {
        //     data: data,
        //     id: this.itemId
        //   }
        // }

        this.$store.dispatch(saveType, data).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if (this.type === 'update') {
                this.openNotify('success', 'common_notificationRecordChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
              }
              this.$emit('close')
              this.$emit('reload')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }
    }

  }

</script>

<style lang="scss" scoped>
  @media(max-width: 550px){
    .transaction-info__col{
      width: 100%;
    }

    .scoped-btns > button{
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
      margin-bottom: 10px;
    }
  }
</style>
