import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function FreshChat() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    keyword: '',
    status: false,
  }


  this.validation = {
    id: false,
    keyword: false,
    status: false,
  }

  this.validationTranslate = {
    id: '',
    keyword: '',
    status: '',
  }

  this.validationTxt = {
    id: false,
    keyword: false,
    status: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getKeyword = () => {
    return this.data.keyword
  }
  this.getStatus = () => {
    return this.data.status
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }

  this.setKeyword = (val) => {
    this.data.keyword = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }

}

/**
 * Global Setters
 */

FreshChat.prototype.setItem = function(val) {

  this.setId(val.id)
  this.setKeyword(val.keyword)
  this.setStatus(val.is_enabled === '1')
}


/**
 * Prepare Data
 */

FreshChat.prototype.prepareSave = function() {
  return {
    keyword: this.getKeyword(),
    is_enabled: this.getStatus() ? '1' : '0',
  }
}


/**
 * Validations
 */

FreshChat.prototype.firstValidation = function() {
  let validationItems = {
    keyword: this.getKeyword(),
  }

  let validationOptions = {
    keyword: {type: ['empty']}
  }

  return this.checkValid(validationItems, validationOptions)
}
